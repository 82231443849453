<template>
  <v-snackbar
    v-model="showSnackbar"
    v-bind="{ ...(isMobile ? { bottom: true } : { top: true, left: true }) }"
    :timeout="-1"
    :class="{ 'd-none': tutorialsToRecommend.length === 0 }"
  >
    <app-icon-btn
      class="snackbar__btn--close"
      color="white"
      icon="mdi-close"
      size="1rem"
      text
      @click="onClose"
    />

    <template v-if="tutorialsToRecommend.length > 0">
      <v-row dense>
        <v-col cols="auto">
          <icon-tutorials />
        </v-col>
        <v-col>
          <span class="subtitle-1">
            {{ $tc('helpCenter.tutorials.recommendedVideosCounted', tutorialsToRecommend.length) }}:
          </span>
          <br />
          <ul v-if="tutorialsToRecommend.length > 1">
            <li v-for="tutorial in tutorialsToRecommend" :key="tutorial.title">
              <app-text-btn
                color="white"
                class="body-1 font-weight-bold mt-1"
                underlined
                @click="showTutorial(tutorial)"
              >
                {{ tutorial.title }}
              </app-text-btn>
            </li>
          </ul>

          <app-text-btn
            v-else
            color="white"
            class="body-1 font-weight-bold mt-1"
            underlined
            @click="showTutorial(tutorialsToRecommend[0])"
          >
            {{ tutorialsToRecommend[0].title }}
          </app-text-btn>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="auto">
          <v-checkbox
            v-model="hideNextTime"
            :label="$t('helpCenter.tutorials.dontShowAgain')"
            hide-details
          />
        </v-col>
        <v-col cols="auto">
          <app-link-btn color="white" title="navigation.close" variant="muted" @click="onClose" />
        </v-col>
      </v-row>
    </template>
  </v-snackbar>
</template>

<script>
import TutorialsMixin from '@/mixins/TutorialsMixin';

export default {
  name: 'TutorialSnackbar',

  mixins: [TutorialsMixin],

  data: () => ({
    snackbar: false,
    hideNextTime: false,
    tutorialsRecommendedBefore: []
  }),

  computed: {
    tutorialsToRecommend() {
      return this.tutorials.filter(
        (tutorial) =>
          tutorial.condition !== false &&
          !this.tutorialsRecommendedBefore.includes(tutorial.videoId)
      );
    },

    showSnackbar: {
      get() {
        return this.snackbar && this.tutorialsToRecommend.length > 0;
      },
      set(value) {
        this.snackbar = value;
      }
    }
  },

  watch: {
    tutorialsToRecommend(value) {
      if (value.length === 0) {
        if (this.snackbar) {
          this.snackbar = false;
        }
        return;
      }

      setTimeout(() => {
        this.snackbar = true;
      }, 2000);
    }
  },

  methods: {
    onClose() {
      if (this.hideNextTime) {
        this.$eventHub.$emit('update-tutorial-settings', false);
      }

      this.snackbar = false;

      setTimeout(() => {
        this.tutorialsToRecommend.forEach((tutorial) =>
          this.tutorialsRecommendedBefore.push(tutorial.videoId)
        );
        if (this.hideNextTime) {
          this.hideNextTime = false;
        }
      }, 500);
    },

    showTutorial(tutorial) {
      setTimeout(() => {
        this.tutorialsRecommendedBefore.push(tutorial.videoId);
      }, 500);

      this.$router.push({
        name: this.routeName.HELP_CENTER,
        query: {
          [this.queryParam.TAB]: 2,
          [this.queryParam.SHOW_VIDEO]: tutorial.videoId,
          [this.queryParam.TAG]: tutorial.tags
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.v-snack {
  max-width: min(100vw, 500px);

  ::v-deep {
    .v-snack__content {
      max-width: 100%;
      padding-right: 24px;
    }

    .v-snack__wrapper {
      position: relative;
    }

    .snackbar__btn--close {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    .v-label {
      color: white;
      font-size: 14px;
    }

    .v-input--checkbox {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
